import React from "react";
import { connect } from "react-redux";

import DetailComponent from "../components/TeamDetailComponent";
import Footer from "../components/Footer";
import HeroAbout from "../cms/HeroAbout";

const TeamDetail = (props) => {
  return (
    <>
      <DetailComponent person={props.person} />
      <HeroAbout />
      <Footer />
    </>
  );
};

const mapStateToProps = (state, props) => {
  const person = state.team.find(
    (person) => person.id === + 0 // props.match.params.id
  );

  return { person };
};

export default connect(mapStateToProps, null)(TeamDetail);
