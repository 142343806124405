import React from "react";
import { Link } from "react-router-dom";
import ReactiveButton from "reactive-button";

const PortfolioDetailComponent = (props) => {
  const { title, src, desc, date, category, link, technologies } = props.item;

  return (
    <>
      <div
        className="container text-white mt-5"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="detail section-title mb-2">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="detail-image mt-5">
                <img src={src} alt="detail" className="banner-image" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 text-left">
              <div className="detail-infotitle-font-2 mt-5">
                <h2 className="work-title mb-2">{title}</h2>
                <h3 className="description title-text mt-2 mb-2">{desc}</h3>
                <div className="align-items-center mt-3 mb-2">
                  <a href={link} target="_blank" rel="noreferrer">
                    <ReactiveButton
                      idleText="View Site"
                      color="dark"
                      size="large"
                      rounded
                    />
                  </a>
                </div>
                <p className="description title-text mt-2 mb-2 ">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <small>{link}</small>
                  </a>
                </p>
                <div className="work-item-date mb-1">
                  <span className="date-title pr-3">Date:</span>
                  <span className="work-date">{date}</span>
                </div>
                <div className="work-item-category mb-1">
                  <span className="category-title pr-3">Category:</span>
                  <span className="work-category">{category}</span>
                </div>
                <div className="work-item-tech mb-1">
                  <span className="category-title pr-3">Technologies:</span>
                  <span className="work-category">{technologies}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-4">
          <div className="align-items-center mb-3 mt-3">
            <Link to={`/portfolio`}>
              <ReactiveButton
                idleText="Back"
                color="light"
                size="small"
                rounded
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioDetailComponent;
