
function getEventValueFromMetric(metric) {
  if (metric.name === "CLS") {
    return Math.round(metric.value * 1000);
  }
  return Math.round(metric.value);
}

function reportHandler(metric) {
    window.ga('send', 'event', {
        eventCategory: 'Web Vitals',
        eventAction: metric.name,
        eventValue: getEventValueFromMetric(metric),
        eventLabel: metric.id,
        nonInteraction: true,
      });
}

export default reportHandler;
