import React from "react";
import ReactiveButton from "reactive-button";
import { Link } from "react-router-dom";

const TeamDetailComponent = (props) => {
  const {
    image,
    authorLink,
    authorTitle,
    authorDescription,
    paragraph1,
    paragraph2,
  } = props.person;

  return (
    <>
      <div className="container">
        <div className="detail section-title text-white">
          <div className="row align-items-center">
            <div className="col-md-7 mx-auto">
              <div className="detail-title">
                <h2 className="title-font mt-4">About</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="detail-image-box mb-2">
                <img src={image} alt="detail" className="banner-image" />
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="detail-info text-left title-font-2">
                <h2 className="work-title">Andrew (Andy) Cary</h2>
                <p className="description title-text mt-2 mb-2">{paragraph1}</p>
                <p className="description title-text mt-2 mb-2">{paragraph2}</p>
                <p className="description title-text mt-4 mb-4 ">
                  <small>
                    Author:{" "}
                    <a
                      href={authorLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {authorTitle}
                    </a>
                    <br />
                    {authorDescription}
                  </small>
                </p>
              </div>
              <div className="align-items-center mt-3 mb-3">
                <a
                  href="https://www.linkedin.com/in/andrewdcary/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <ReactiveButton
                    idleText="LinkedIn"
                    color="dark"
                    size="large"
                    rounded
                  />
                </a>
                <a
                  href="https://github.com/acary"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <ReactiveButton
                    idleText="GitHub"
                    color="info"
                    size="large"
                    rounded
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="mt-3">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="text-white mb-1">
                <Link to="/contact" className="nav-link">
                  <h4 className="title-font">
                    Let's build the future together
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TeamDetailComponent;
