import React, { useState } from 'react'
import ReactiveButton from 'reactive-button';
import { send } from 'emailjs-com'
// Notifications
import toast from 'react-hot-toast'

const Contact = () => {
  const [state, setState] = useState('idle');
  const [toSend, setToSend] = useState({
      from_name: '',
      subject: '',
      message: '',
      reply_to: '',
    })

    const onClickHandler = () => {
        setState('loading');
        setTimeout(() => {
            setState('success');
        }, 2000);
    }

    const onSubmit = (e) => {
      e.preventDefault();
      send(
        'service_yp6885i',
        'template_mq1jslo',
        toSend,
        'user_5bQagzobdEKupULSjq1Ym'
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text)
          toast.success("Message sent! Thanks!")
          setToSend({
              from_name: '',
              subject: '',
              message: '',
              reply_to: '',
            })
        })
        .catch((err) => {
          console.log('FAILED...', err)
        })
    }

    const handleChange = (e) => {
      setToSend({ ...toSend, [e.target.name]: e.target.value })
    }

    return(
        <>
            <div className="contact section-title mt-5">
                <div className="container text-white">
                    <div className="row align-items-center">
                        <div className="col-md-7 mx-auto">
                            <div className="contact-title mb-5 mt-5">
                                <h1 className="title-font title-font-size">
                                Contact
                                </h1>
                                <p className="mt-4 mb-4 title-font-2">
                                    Let's have a chat! What's on your mind?
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-8 mx-auto text-white">
                            <div className="contact-form mb-5 mt-5">
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <input
                                              required
                                              name='from_name'
                                              value={toSend.from_name}
                                              onChange={handleChange}
                                              type="text"
                                              className="form-control"
                                              placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <input
                                              required
                                              name='reply_to'
                                              value={toSend.reply_to}
                                              onChange={handleChange}
                                              type="email"
                                              className="form-control"
                                              placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <input
                                              required
                                              name='subject'
                                              value={toSend.subject}
                                              onChange={handleChange}
                                              type="text"
                                              className="form-control"
                                              placeholder="Subject"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <textarea
                                              required
                                              name='message'
                                              value={toSend.message}
                                              onChange={handleChange}
                                              className="form-control"
                                              placeholder="Message"
                                              rows="4"/>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <ReactiveButton
                                          type="submit"
                                          buttonState={state}
                                          onClick={onClickHandler}
                                          color={'primary'}
                                          idleText={'Send'}
                                          loadingText={'Loading'}
                                          successText={'Sent!'}
                                          errorText={'Error'}
                                          className={'class1 class2'}
                                          style={{ borderRadius: '5px' }}
                                          outline={false}
                                          shadow={false}
                                          rounded={false}
                                          size={'normal'}
                                          block={false}
                                          messageDuration={2000}
                                          disabled={false}
                                          buttonRef={null}
                                          width="300px"
                                          height={null}
                                          animation={true}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
