import React, { Component } from "react";
import { connect } from "react-redux";

import PortfolioPage from "../cms/PortfolioPage";
import Footer from "./Footer";

class Portfolio extends Component {
  render() {
    return (
      <>
        <div className="container">
          <section className="portfolio section-title mt-2 text-white">
            <div className="row align-items-center">
              <div className="col-12 mx-auto">
                <div className="portfolio-title text-white mb-2 mt-4">
                  <h2 className="title-font mt-5">Portfolio</h2>
                </div>
                <div>
                  <p>
                    As a Full Stack Software Engineer, the following projects
                    showcase end-to-end products I developed to continue
                    learning and applying industry best-practices.
                  </p>
                </div>
              </div>
            </div>
            <PortfolioPage />
          </section>
          <section className="about-us-area text-white my-1">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="about-us-text-area mb-1 mt-1">
                  <h2 className="about-us-text title-font-3">
                    Making the abstract and conceptual concrete
                  </h2>
                  <p className="mt-2 mb-3 title-font-2">
                    Software has the power to harness our ideas, thoughts and
                    ways of doing things. My portfolio displays expressions from
                    my perspective and how I accomplish them.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.works,
  };
};

export default connect(mapStateToProps)(Portfolio);
