export const team = [
  {
    id: 0,
    image: "/images/andy-bluebg.png",
    name: "Andy Cary",
    position: "Web Developer",
    authorLink: "https://adroit.andycary.com/",
    authorTitle: "Adroit Development",
    authorDescription:
      "A guide to building, testing and deploying modern systems.",
    paragraph1:
      "Andy Cary is a Senior Software Engineer specializing in building usable, performant, reliable and maintainable web applications. He earned his Master of Science in Engineering (MSE) from The University of Texas at Austin (UT) and his Bachelor of Science, Information Science (BSIS) from The University of North Carolina at Chapel Hill (UNC). ",
    paragraph2:
      "He is currently employed at The Home Depot (Fortune 20) on the Pro Order Management (Order Visibility) team. He previously worked as REI Systems, Inc. in the Open Government, Science and Technology unit developing a new data management platform for FEMA, a software developer at VidDay Media, Inc. and Stanford University IT.",
  },
];
