import { Link } from "react-router-dom";
import { useSinglePrismicDocument } from "@prismicio/react";

import "./Navbar.scss";

const Navbar = () => {
  const [document, { state }] = useSinglePrismicDocument("navigation");
  const logo = document?.data?.logo.url;
  if (state !== "loading") {
    return (
      <header className="header-transparent">
        <nav className="navbar navbar-expand-lg text-center">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={logo} className="brand-logo" alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fi-xwluxl-three-bars-wide"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={`/about`} className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/portfolio" className="nav-link">
                    Portfolio
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="https://newsletter.andycary.com" className="nav-link">
                    Newsletter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
};

export default Navbar;
