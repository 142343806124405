import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import HomeBuilder from "./cms/HomeBuilder";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import PortfolioDetail from "./pages/PortfolioDetail";
import Works from "./pages/Works";
import TeamDetail from "./pages/TeamDetail";
import { NotFoundPage } from "./cms/NotFoundPage";
import "./App.scss";
import { init } from "emailjs-com";
import { Toaster } from "react-hot-toast";

init("user_5bQagzobdEKupULSjq1Ym"); // EmailJS

const App = () => {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <ScrollToTop />
        <Navbar />
        <Toaster position="bottom-right" reverseOrder={true} />
        <Switch>
          <Route
            exact
            path="/podcast"
            component={() => {
              window.location.href = "https://2020.andycary.com/podcast/";
              return null;
            }}
          />
          <Route exact path="/" component={HomeBuilder} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/portfolio" component={Works} />
          <Route exact path={"/portfolio/:id"} component={PortfolioDetail} />
          <Route exact path={"/about"} component={TeamDetail} />
          <Route exact path={"/about/:id"} component={TeamDetail} />
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
