import HappyHourCaterer from '../img/socialevents/happy2.jpg'
import EntityCo from '../img/portfolio/entityCo.webp'

export const works = [
    {
        "id": 1,
        "src": [EntityCo],
        "desc": "Refresh your outdoor lifestyle for amazing adventures",
        "title": "EntityCo",
        "date": "October 2023",
        "category": "Lifestyle",
        "link": "https://getentityco.onrender.com",
        "technologies": "React, ExpressJS, NodeJS, MongoDB, Material Design, APIs, Swagger, Render"
    },
    {
        "id": 2,
        "src": [HappyHourCaterer],
        "desc": "On-demand charcuterie for peer-to-peer bid and fulfillment",
        "title": "Happy Hour Caterer",
        "date": "November 2022",
        "category": "Marketplace",
        "link": "https://happyhourcaterer.vercel.app",
        "technologies": "React, DynamoDB, AWS Lambda, API Gateway, Amplify, Cognito, Serverless"
    }
]
