import React from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    return (
        <>
            <div className="container text-white" style = {{
                height:"100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}>
                <p>Oops! We couldn't find that page. <Link to="/">Return home!</Link></p>
            </div>
        </>
    );
}