import React from "react";
import { Typography } from "@material-tailwind/react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 mt-3">
            <span>
              <Typography
                color="inherit"
                className="font-normal"
                placeholder={undefined}
                variant="small"
                textGradient={true}
              >
                &copy; 2024 Andy Cary
              </Typography>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
