import React from 'react';

const LetsWorkTogether = () => {
    return(
        <section className="lead">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="lead-title text-white mb-5 mt-4">
                            <h4 className="title-font">
                            Let's build the future together
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LetsWorkTogether
