import React, { useState, useEffect } from "react";
import { BuilderComponent, builder } from "@builder.io/react";

const builderKey = process.env.REACT_APP_BUILDER_TOKEN;
builder.init(builderKey);

export default function HeroAbout() {
  const [hero, setHero] = useState(null);
  useEffect(() => {
    builder
      .get("page", {
        query: {
          data: {
            uuid: 3,
          },
        },
      })
      .toPromise()
      .then((homepageData) => {
        setHero(homepageData);
      });
  }, []);

  return <>{hero && <BuilderComponent model="page" content={hero} />}</>;
}
