import React from 'react';
import LetsWork from '../components/LetsWorkTogether';

// service icons
import share from '../img/services/share.png';
import web from '../img/services/web.png';


const Services = () => {

    const serviceIcons = [
        {
            "id": "1",
            "title": "App Architecture & Strategy",
            "image": share,
            "description": "From inception to launch, I can chart a path for new products and services."
        },
        {
            "id": "1",
            "title": "Web Design & Development",
            "image": web,
            "description": "From rapid prototyping to production, I can develop modern, scalable software systems."
        }
    ]

    return(
        <>
        <div className="services section-title mt-5">
            <div className="container text-white">
                <div className="row align-items-center">
                    <div className="col-md-7 mx-auto">
                        <div className="services-title mb-5 mt-5">
                            <h1 className="title-font title-font-size">
                                Services
                            </h1>
                            <p className="title-text mt-4 mb-4 title-font-2">
                                Custom development for your top business needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    {
                        serviceIcons.map(service => (
                            <div className="col-12 col-lg-6" key={service.id}>
                                <div className="service-boxes">
                                    <div className="service-icon">
                                    <img className="service-icon-img mb-3" src={service.image} alt="instagram"/>
                                    </div>
                                    <div className="service-info-title">
                                        <h3 className="service-name title-font-2">
                                            {service.title}
                                        </h3>
                                    </div>
                                    <div className="service-info">
                                        <p className="service-description text-left title-font-2 mt-3">
                                        {service.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

        <LetsWork/>
        </>
    );
}

export default Services;
